:global div.pro-gallery .gallery-item-container.zoom-in-on-hover:not(.hide-hover) .gallery-item-content {
  transition: transform 2.2s cubic-bezier(0.14, 0.4, 0.09, 0.99) !important; }

:global div.pro-gallery .gallery-item-container.zoom-in-on-hover:not(.hide-hover).simulate-hover .gallery-item-content, :global div.pro-gallery .gallery-item-container.zoom-in-on-hover:not(.hide-hover):hover .gallery-item-content {
  transform: scale(1.1); }

:global div.pro-gallery .gallery-item-container.blur-on-hover:not(.hide-hover) .gallery-item-content {
  transition: filter .4s linear !important; }

:global div.pro-gallery .gallery-item-container.blur-on-hover:not(.hide-hover).simulate-hover .gallery-item-content .gallery-item, :global div.pro-gallery .gallery-item-container.blur-on-hover:not(.hide-hover):hover .gallery-item-content .gallery-item {
  filter: blur(6px); }

:global div.pro-gallery .gallery-item-container.grayscale-on-hover:not(.hide-hover) .gallery-item-content {
  transition: filter .6s ease !important; }

:global div.pro-gallery .gallery-item-container.grayscale-on-hover:not(.hide-hover).simulate-hover .gallery-item-content, :global div.pro-gallery .gallery-item-container.grayscale-on-hover:not(.hide-hover):hover .gallery-item-content {
  filter: grayscale(1); }

:global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover) {
  transition: background-color .4s ease !important; }
  :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover) .gallery-item-content,
  :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover) .gallery-item-hover:not(.hide-hover) {
    transition: transform .4s ease !important; }
  :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover).simulate-hover, :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover):hover {
    background-color: transparent !important; }
    :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover).simulate-hover .gallery-item-content, :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover):hover .gallery-item-content {
      transform: scale(0.985); }
    :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover).simulate-hover .gallery-item-hover:not(.hide-hover), :global div.pro-gallery .gallery-item-container.shrink-on-hover:not(.hide-hover):hover .gallery-item-hover:not(.hide-hover) {
      transform: scale(0.985); }

:global div.pro-gallery .gallery-item-container.invert-on-hover:not(.hide-hover) .gallery-item-content {
  transition: filter .6s ease !important; }

:global div.pro-gallery .gallery-item-container.invert-on-hover:not(.hide-hover).simulate-hover .gallery-item-content, :global div.pro-gallery .gallery-item-container.invert-on-hover:not(.hide-hover):hover .gallery-item-content {
  filter: invert(1); }

:global div.pro-gallery .gallery-item-container.color-in-on-hover .gallery-item-content {
  filter: grayscale(1);
  transition: filter .6s ease !important; }

:global div.pro-gallery .gallery-item-container.color-in-on-hover.simulate-hover:not(.hide-hover) .gallery-item-content, :global div.pro-gallery .gallery-item-container.color-in-on-hover:hover:not(.hide-hover) .gallery-item-content {
  filter: grayscale(0); }

:global div.pro-gallery .gallery-item-container.darkened-on-hover .gallery-item-content {
  filter: brightness(1);
  transition: filter .6s ease !important; }

:global div.pro-gallery .gallery-item-container.darkened-on-hover.simulate-hover:not(.hide-hover) .gallery-item-content, :global div.pro-gallery .gallery-item-container.darkened-on-hover:hover:not(.hide-hover) .gallery-item-content {
  filter: brightness(0.7); }

:global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover {
  transition: none; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover:before {
    opacity: 0; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover .gallery-item-hover-inner {
    opacity: 0; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover,
:global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) {
  transition: none; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover:before,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover):before {
    opacity: 1;
    background: rgba(8, 8, 8, 0.75); }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover .gallery-item-social-share-box:not(.hidden),
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover):hover .gallery-item-hover:not(.hide-hover) .gallery-item-social-share-box:not(.hidden) {
    opacity: 1 !important; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover {
  transition: filter .4s ease, opacity .4s ease !important; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover:before {
    transition: filter .4s ease, opacity .4s ease !important;
    filter: opacity(0); }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-fade-in:hover .gallery-item-hover:not(.hide-hover):before {
  filter: opacity(1); }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover {
  transition: transform .4s ease, filter .2s ease, opacity .2s ease !important; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover:before {
    transition: transform .4s ease, filter .2s ease, opacity .2s ease !important;
    transform: scale(0.9);
    filter: opacity(0); }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .2s ease; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-expand:hover .gallery-item-hover:not(.hide-hover):before {
  transform: scale(1);
  filter: opacity(1); }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover {
  transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover:before {
    transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important;
    transform: translateY(100%);
    filter: opacity(0); }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-up:hover .gallery-item-hover:not(.hide-hover):before {
  transform: translateY(0);
  filter: opacity(1); }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover {
  transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important; }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover:before {
    transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important;
    transform: translateX(-100%);
    filter: opacity(0); }
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container:not(.invert-hover).hover-animation-slide-right:hover .gallery-item-hover:not(.hide-hover):before {
  transform: translateX(0);
  filter: opacity(1); }

:global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover {
  transition: none; }
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover:before {
    opacity: 1;
    background: rgba(8, 8, 8, 0.75); }
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-hover-inner {
    opacity: 1; }
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    opacity: 1 !important; }

:global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover,
:global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) {
  transition: none; }
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover:before,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover):before {
    opacity: 0; }
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover .gallery-item-hover.force-hover .gallery-item-social-share-box:not(.hidden),
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover:hover .gallery-item-hover:not(.hide-hover) .gallery-item-social-share-box:not(.hidden) {
    opacity: 0 !important; }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover {
  transition: filter .4s ease, opacity .4s ease !important; }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover:before {
    transition: filter .4s ease, opacity .4s ease !important;
    filter: opacity(1); }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-fade-in:hover .gallery-item-hover:not(.hide-hover):before {
  filter: opacity(0); }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover {
  transition: transform 0.4s ease, filter .2s ease, opacity .2s ease !important; }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover:before {
    transition: transform 0.4s ease, filter .2s ease, opacity .2s ease !important;
    transform: scale(1);
    filter: opacity(1); }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .2s ease; }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-expand:hover .gallery-item-hover:not(.hide-hover):before {
  transform: scale(0.9);
  filter: opacity(0); }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover {
  transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important; }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover:before {
    transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important;
    transform: translateY(0);
    filter: opacity(1); }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-up:hover .gallery-item-hover:not(.hide-hover):before {
  transform: translateY(100%);
  filter: opacity(0); }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover {
  transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important; }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover:before {
    transition: transform 0.4s cubic-bezier(0.3, 0.13, 0.12, 1), filter 0.5s ease, opacity 0.5s ease !important;
    transform: translateX(0);
    filter: opacity(1); }
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .gallery-item-hover-inner,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .gallery-item-social-button,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .gallery-item-title,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .gallery-item-description,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .custom-button-wrapper,
  :global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover .gallery-item-social-share-box:not(.hidden) {
    transition: opacity .4s ease; }

:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right .gallery-item-hover.force-hover:before,
:global div.pro-gallery .gallery-item-container.invert-hover.hover-animation-slide-right:hover .gallery-item-hover:not(.hide-hover):before {
  transform: translateX(-100%);
  filter: opacity(0); }

:global *[data-collapsed="true"] .pro-gallery-parent-container .gallery-item,
:global *[data-hidden="true"] .pro-gallery-parent-container .gallery-item {
  background-image: none !important; }

:global html.pro-gallery {
  width: 100%;
  height: auto; }

:global body.pro-gallery {
  transition: opacity 2s ease; }

:global #gallery-loader {
  position: fixed;
  top: 50%; }

:global .show-more-container {
  text-align: center;
  line-height: 138px; }
  :global .show-more-container i.show-more {
    color: #5d5d61;
    font-size: 40px;
    cursor: pointer;
    margin-top: -3px; }
  :global .show-more-container button.show-more {
    display: inline-block;
    padding: 11px 29px;
    border-radius: 0;
    border: 2px solid #5d5d61;
    font-family: "HelveticaNeueW01-45Ligh", "HelveticaNeueW02-45Ligh", "HelveticaNeueW10-45Ligh", sans-serif;
    font-size: 12px;
    color: #5d5d61;
    background: transparent;
    cursor: pointer; }
    :global .show-more-container button.show-more:hover {
      background: rgba(0, 0, 0, 0.1); }

:global .more-items-loader {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #3899ec; }

:global .version-header {
  color: #e03939;
  text-align: left;
  font-family: "Consolas", monospace;
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 100px;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100; }

:global .auto-slideshow-button {
  margin-top: 19px;
  padding: 5px;
  height: 28px;
  width: 20px;
  left: auto;
  z-index: 1;
  position: absolute;
  display: flex;
  text-align: center;
  cursor: pointer;
  opacity: 0.9; }

:global .auto-slideshow-counter {
  margin-top: 24px;
  left: auto;
  z-index: 1;
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.9;
  font-size: 15px;
  line-height: normal; }

:global .nav-arrows-container {
  left: auto;
  z-index: 1;
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
  font-size: 40px;
  align-items: center;
  background: transparent;
  border: none;
  outline: none; }
  :global .nav-arrows-container:hover {
    opacity: 1; }
  :global .nav-arrows-container.prev {
    justify-content: flex-end; }
  :global .nav-arrows-container.next {
    justify-content: flex-start; }
  :global .nav-arrows-container svg {
    filter: drop-shadow(0px 1px 0.15px #B2B2B2); }

:global div.gallery-slideshow div.pro-gallery,
:global div.gallery-slideshow .gallery-column {
  box-sizing: content-box !important; }

:global div.gallery-slideshow .gallery-group,
:global div.gallery-slideshow .gallery-item-container,
:global div.gallery-slideshow .gallery-item-wrapper {
  overflow: visible !important; }

:global div.gallery-slideshow.streched .gallery-item-info {
  padding-left: 50px !important;
  padding-right: 50px !important; }

@media (max-width: 500px) {
  :global div.gallery-slideshow div.pro-gallery .gallery-item-info {
    padding-left: 20px;
    padding-right: 20px; } }

:global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info {
  position: absolute;
  padding-top: 0px; }
  :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info > div {
    padding-top: 24px; }
  :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info.gallery-item-bottom-info .custom-button-wrapper button {
    margin-top: 0 !important;
    outline: none; }
  :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-text {
    flex-grow: 1;
    padding: 0;
    margin-bottom: 25px; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-text .gallery-item-title {
      max-width: 800px;
      white-space: normal; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-text .gallery-item-description {
      max-width: 800px; }
      :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-text .gallery-item-description > span {
        white-space: normal; }
  :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) {
    height: auto;
    position: static;
    display: flex;
    flex-direction: row;
    margin: 0; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).populated-item {
      margin-bottom: 24px; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item):hover .gallery-item-social-share-box {
      width: 0 !important; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-center {
      justify-content: center; }
      :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-center .gallery-item-social-share {
        position: relative; }
        :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-center .gallery-item-social-share .gallery-item-social-share-box {
          position: absolute;
          left: -25px;
          padding-left: 25px !important;
          margin-right: 0 !important; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-right {
      flex-direction: row-reverse; }
      :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-right .gallery-item-social-share {
        flex-direction: row-reverse; }
        :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item).gallery-item-direction-right .gallery-item-social-share:hover .gallery-item-social-share-box {
          margin-right: 40px !important; }
    :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) .gallery-item-social-share {
      flex-direction: row;
      outline: none; }
      :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) .gallery-item-social-share:hover .gallery-item-social-share-box {
        width: 210px !important;
        outline: none; }
      :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) .gallery-item-social-share .gallery-item-social-share-box {
        width: 0;
        transition: width 0.3s;
        overflow: hidden;
        margin-left: 25px !important;
        margin-right: 25px !important; }
        :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) .gallery-item-social-share .gallery-item-social-share-box.hovered {
          width: 210px !important;
          outline: none; }
        :global div.gallery-slideshow div.pro-gallery .gallery-item-container .gallery-item-info .gallery-item-social:not(.text-external-item) .gallery-item-social-share .gallery-item-social-share-box.opened {
          width: 210px !important;
          outline: none; }

:global div.gallery-slideshow div.pro-gallery .gallery-item-bottom-info .gallery-item-title {
  white-space: normal; }

@media (max-width: 500px) {
  :global .gallery-slider div.pro-gallery .gallery-item-info,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-info {
    padding-left: 20px;
    padding-right: 20px; } }

:global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover,
:global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover {
  display: flex;
  flex-direction: column; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.flex-start,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.flex-start {
    justify-content: flex-start; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.center,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.center {
    justify-content: center; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.flex-end,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.flex-end {
    justify-content: flex-end; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.padded-horizontal,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.padded-horizontal {
    padding: 0 20px; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social,
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social {
    margin: 40px 0 0;
    height: auto;
    position: static;
    display: flex;
    flex-direction: row; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.with-arrows,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.with-arrows {
      width: auto; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gradient-top,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gradient-top {
      background: linear-gradient(rgba(0, 0, 0, 0.2) 0, transparent 140px); }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button {
      position: static !important;
      margin: 0;
      padding: 0 20px;
      font-size: 19px; }
      :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-share,
      :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-share {
        margin-top: -3px; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social:hover .gallery-item-social-share-box,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social:hover .gallery-item-social-share-box {
      width: 0 !important; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center {
      justify-content: center; }
      :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center .gallery-item-social-share,
      :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center .gallery-item-social-share {
        position: relative; }
        :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center .gallery-item-social-share .gallery-item-social-share-box,
        :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-center .gallery-item-social-share .gallery-item-social-share-box {
          position: absolute;
          left: -25px;
          padding-left: 25px !important;
          margin-right: 0 !important; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right {
      flex-direction: row-reverse; }
      :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right .gallery-item-social-share,
      :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right .gallery-item-social-share {
        flex-direction: row-reverse; }
        :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right .gallery-item-social-share:hover .gallery-item-social-share-box,
        :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.gallery-item-direction-right .gallery-item-social-share:hover .gallery-item-social-share-box {
          margin-right: 40px !important; }
    :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share,
    :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share {
      flex-direction: row;
      outline: none; }
      :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share:hover .gallery-item-social-share-box,
      :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share:hover .gallery-item-social-share-box {
        width: 210px !important;
        outline: none; }
      :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share .gallery-item-social-share-box,
      :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share .gallery-item-social-share-box {
        width: 0;
        transition: width 0.3s;
        overflow: hidden;
        margin-left: 25px !important;
        margin-right: 25px !important; }
        :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share .gallery-item-social-share-box.hovered,
        :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-share .gallery-item-social-share-box.hovered {
          width: 210px !important;
          outline: none; }

:global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover {
  padding: 50px 100px; }
  :global .gallery-thumbnails div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social:not(.text-external-item).with-arrows {
    margin: 40px -10px 0; }

:global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover {
  padding: 30px 10px; }
  :global .gallery-slider div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.with-arrows {
    margin: 40px -10px 0; }

:global .gallery-slider div.pro-gallery .gallery-item-bottom-info .gallery-item-title, :global .gallery-slider div.pro-gallery .gallery-item-top-info .gallery-item-title {
  white-space: normal; }

:global .gallery-columns div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover {
  padding: 30px; }
  :global .gallery-columns div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.padded-horizontal {
    padding-left: 0;
    padding-right: 0; }

:global div.pro-gallery {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  position: relative; }
  :global div.pro-gallery .gallery-column {
    float: left;
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden; }
    :global div.pro-gallery .gallery-column .gallery-left-padding {
      display: inline-block;
      height: 100%; }
    :global div.pro-gallery .gallery-column .gallery-top-padding {
      display: block;
      width: 100%; }
  :global div.pro-gallery .gallery-group {
    float: left;
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    box-sizing: border-box;
    padding: 0;
    font-family: "HelveticaNeueW01-45Ligh", "HelveticaNeueW02-45Ligh", "HelveticaNeueW10-45Ligh", sans-serif;
    font-size: 11px;
    transition: width .4s ease, height .4s ease; }
    :global div.pro-gallery .gallery-group.debug.gallery-group-gone {
      background: #cdcdd0; }
    :global div.pro-gallery .gallery-group.debug.gallery-group-visible {
      background: #c1f0c1; }
    :global div.pro-gallery .gallery-group.debug.gallery-group-hidden {
      background: #ff9999; }
  :global div.pro-gallery .gallery-item-container {
    position: absolute;
    display: inline-block;
    vertical-align: top;
    border: none;
    padding: 0;
    border-radius: 0;
    box-sizing: border-box;
    overflow: hidden;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    outline: none;
    text-decoration: none;
    color: inherit;
    will-change: transform, top, left, width, height;
    box-sizing: border-box;
    font-family: "HelveticaNeueW01-45Ligh", "HelveticaNeueW02-45Ligh", "HelveticaNeueW10-45Ligh", sans-serif;
    font-size: 11px;
    cursor: default;
    scroll-snap-align: center; }
    :global div.pro-gallery .gallery-item-container:hover .gallery-item-bottom-info, :global div.pro-gallery .gallery-item-container:hover .gallery-item-top-info {
      cursor: pointer; }
      :global div.pro-gallery .gallery-item-container:hover .gallery-item-bottom-info button, :global div.pro-gallery .gallery-item-container:hover .gallery-item-top-info button {
        text-decoration: underline;
        cursor: pointer; }
    :global div.pro-gallery .gallery-item-container.highlight:before {
      content: '';
      z-index: 999;
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.6); }
    :global div.pro-gallery .gallery-item-container.visible {
      transform: translate3d(0, 0, 0); }
    :global div.pro-gallery .gallery-item-container.clickable {
      cursor: pointer; }
    :global div.pro-gallery .gallery-item-container .gallery-item-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all .4s ease;
      transition-property: top, left, width, height; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper.transparent, :global div.pro-gallery .gallery-item-container .gallery-item-wrapper.cube-type-fit {
        background: transparent; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-preload {
        display: none; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper.cube-type-fit .gallery-item {
        background: transparent;
        object-fit: contain; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
        border-radius: 0; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item {
          box-sizing: border-box;
          /*! autoprefixer: ignore next */
          -webkit-font-smoothing: antialiased;
          white-space: initial; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item .te-pro-gallery-text-item {
            line-height: normal !important;
            letter-spacing: normal !important; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item > div {
            background: initial !important;
            box-sizing: border-box; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item p,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item span,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item div,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h1,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h2,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h3,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h4,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h5,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item h6,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.text-item i {
            margin: 0;
            padding: 0; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item .pro-circle-preloader {
          top: 50%;
          left: 50%;
          height: 30px;
          width: 15px;
          z-index: -1;
          opacity: 0.4; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item img.gallery--placeholder-item {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          object-position: center; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-loaded {
          background-color: transparent;
          opacity: 1 !important;
          animation: none !important; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-loaded.image-item:after {
            display: none !important; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-loaded ~ .pro-circle-preloader {
            display: none; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.error {
          opacity: 0 !important; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-preloaded {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-preloaded.grid-fit {
            background-size: contain; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-hidden {
          opacity: 0; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video {
          overflow: hidden;
          text-align: center; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video iframe {
            left: 0;
            top: 0; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video.playing button,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video.playing i {
            display: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i {
            display: inline-block;
            text-rendering: auto;
            /*! autoprefixer: ignore next */
            -webkit-font-smoothing: antialiased;
            position: absolute;
            z-index: 11;
            top: 50%;
            left: 50%;
            height: 60px;
            text-align: center;
            margin: -30px 0 0 -30px;
            background: #080808;
            color: #fff;
            border-radius: 50px;
            opacity: 0.7; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button.play-triangle,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i.play-triangle {
              opacity: 1; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button.play-triangle, :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button.play-background,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i.play-triangle,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i.play-background {
              font-size: 26px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button:hover,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i:hover {
              opacity: 0.9; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video button:before,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item.gallery-item-video i:before {
              font-size: 2.3em;
              opacity: 1; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info {
        position: absolute;
        bottom: -220px;
        height: 220px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        z-index: 15; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info > div {
          height: 100%;
          width: 100%; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-text {
          padding: 30px 0 0;
          display: flex;
          flex-direction: column;
          margin: 0; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-text > div {
            width: 100%; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-title {
          font-family: "HelveticaNeueW01-Thin", "HelveticaNeueW02-Thin", "HelveticaNeueW10-35Thin", sans-serif;
          line-height: 32px;
          font-size: 21px;
          height: auto;
          color: #2b5672;
          display: block;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          transition: opacity 0.4s ease;
          text-overflow: ellipsis; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-description {
          font-family: "HelveticaNeueW01-Thin", "HelveticaNeueW02-Thin", "HelveticaNeueW10-35Thin", sans-serif;
          line-height: 25px;
          font-size: 15px;
          height: auto;
          color: #2b5672;
          overflow: hidden;
          display: block;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          transition: opacity 0.4s ease;
          white-space: nowrap;
          text-overflow: ellipsis; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .custom-button-wrapper {
          transition: opacity 0.4s ease; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .custom-button-wrapper button {
            font-family: "HelveticaNeueW01-Thin", "HelveticaNeueW02-Thin", "HelveticaNeueW10-35Thin", sans-serif;
            line-height: 25px;
            font-size: 15px;
            height: auto;
            color: #2b5672;
            overflow: hidden;
            /*! autoprefixer: ignore next */
            -webkit-box-orient: vertical;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline;
            outline: none; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social {
          overflow: visible;
          z-index: 16;
          transition: opacity 0.4s ease;
          margin: 40px -15px 0; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button {
            margin: 0 15px;
            display: inline-flex;
            font-size: 19px;
            color: #2b5672;
            cursor: pointer; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button:hover:not(.pro-gallery-loved) {
              opacity: 0.7; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button.gallery-item-social-love {
              font-size: 15px;
              border: none;
              background: transparent;
              padding: 0; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button.gallery-item-social-love button,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button.gallery-item-social-love i {
                float: left;
                display: inline-block;
                border: none;
                background: transparent;
                text-decoration: none; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-button.gallery-item-social-love .gallery-item-social-love-count {
                color: #2b5672;
                font-family: "HelveticaNeueW01-UltLt", "HelveticaNeueW02-UltLt", "HelveticaNeueW10-25UltL", sans-serif;
                font-style: normal;
                float: left;
                margin-top: 2px;
                display: inline-block;
                padding-left: 9px;
                letter-spacing: 1px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box {
            display: inline-block;
            height: 30px;
            max-width: 300px;
            width: 210px;
            overflow: visible;
            z-index: 16;
            font-size: 12px;
            transform: none !important;
            transition: opacity 0.4s ease;
            margin: 0 0 0 -10px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box button,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box i {
              display: inline-block;
              font-size: 15px;
              color: #2b5672;
              cursor: pointer;
              width: 30px;
              height: 30px;
              line-height: 14px;
              text-align: center;
              margin: 0 6px;
              float: left;
              text-decoration: none;
              background: transparent;
              border: none; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box button:hover,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box i:hover {
                opacity: 0.7; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box button.twitter-share,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box i.twitter-share {
                font-size: 13px; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box button.email-share,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box i.email-share {
                font-size: 13px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social .gallery-item-social-share-box.hovered {
              width: 210px !important;
              outline: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social:hover .gallery-item-social-share-box {
            width: 210px; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-info .gallery-item-social-share-box {
          display: none; }
      :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover {
        white-space: initial;
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        margin: 0;
        box-sizing: border-box;
        border-radius: 0;
        z-index: 15;
        overflow: hidden; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .custom-button-wrapper {
          display: flex;
          justify-content: center;
          opacity: 0;
          /*! autoprefixer: ignore next */
          -webkit-box-pack: center;
          transition: opacity 0.4s ease;
          color: #fff; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .custom-button-wrapper .buy-icon {
            margin-right: 7px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .custom-button-wrapper button {
            line-height: 25px;
            font-size: 15px;
            color: #fff;
            overflow: hidden;
            display: block;
            /*! autoprefixer: ignore next */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            opacity: 0;
            height: 45px;
            min-width: 190px;
            padding: 0 15px;
            position: relative;
            z-index: 10;
            cursor: pointer;
            white-space: nowrap;
            outline: none; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .custom-button-wrapper button .overlay {
              display: none; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .custom-button-wrapper button:hover .overlay {
              display: block;
              background: rgba(255, 255, 255, 0.1);
              width: 100%;
              height: 45px;
              position: absolute;
              top: 0;
              left: 0; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover.no-hover-bg:before {
          opacity: 0 !important; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover:before {
          content: ' ';
          position: absolute;
          top: -1px;
          left: -1px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          margin: 0;
          box-sizing: border-box;
          z-index: -1; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text {
          padding: 30px;
          display: flex;
          flex-direction: column;
          margin: 0;
          box-sizing: border-box;
          height: 100%; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.short-item {
            padding-top: 5px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.narrow-item {
            padding-left: 5px;
            padding-right: 5px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.hidden {
            opacity: 0 !important;
            pointer-events: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.padded-horizontal {
            padding: 30px 120px 0; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text > div {
            width: 100%; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-text.push-down {
            padding-top: 60px;
            box-sizing: border-box; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-title {
          line-height: 32px;
          font-size: 21px;
          padding: 0;
          color: #fff;
          overflow: hidden;
          display: -webkit-box;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          opacity: 0; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-description {
          line-height: 25px;
          font-size: 15px;
          color: #fff;
          overflow: hidden;
          display: -webkit-box;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          opacity: 0;
          white-space: normal; }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social {
          outline: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: visible;
          z-index: 16;
          transition: opacity 0.4s ease; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.short-item, :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.narrow-item {
            display: flex;
            align-items: flex-end;
            justify-content: space-around;
            height: 90%; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.short-item .gallery-item-social-button, :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.narrow-item .gallery-item-social-button {
              position: initial; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.with-arrows {
            width: 86%;
            margin: 0 7%; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.hidden {
            opacity: 0 !important;
            pointer-events: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button {
            outline: none;
            bottom: 30px;
            position: absolute;
            margin: 0;
            display: inline-block;
            font-size: 19px;
            color: #ffffff;
            cursor: pointer;
            opacity: 0;
            padding: 10px;
            margin: -10px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button:hover:not(.pro-gallery-loved) {
              opacity: 0.7; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.visible {
              opacity: 1 !important; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-love {
              left: 30px;
              bottom: 30px;
              font-size: 15px;
              border: none;
              background: transparent; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-love button,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-love i {
                outline: none;
                float: left;
                display: inline-block;
                line-height: 14px;
                border: none;
                background: transparent;
                font-size: 18px;
                padding: 1px 5px;
                text-decoration: none; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-love .gallery-item-social-love-count {
                font-family: "HelveticaNeueW01-UltLt", "HelveticaNeueW02-UltLt", "HelveticaNeueW10-25UltL", sans-serif;
                font-style: normal;
                float: left;
                line-height: 15px;
                font-size: 15px;
                margin-top: 2px;
                display: inline-block;
                padding-left: 9px;
                letter-spacing: 1px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-share {
              bottom: 26px;
              left: auto;
              right: 30px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-download {
              bottom: 25px;
              left: auto;
              right: 68px; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social .gallery-item-social-button.gallery-item-social-download.pull-right {
                right: 30px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item .gallery-item-social-button {
            bottom: calc(50% - 10px); }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item .gallery-item-social-button.gallery-item-social-love {
              left: calc(100% / 4 - 8px); }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item .gallery-item-social-button.gallery-item-social-love span {
                display: none; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item .gallery-item-social-button.gallery-item-social-share {
              right: calc(100% / 4 - 8px); }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item .gallery-item-social-button.gallery-item-social-download {
              left: calc(50% - 8px); }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item.vertical-item .gallery-item-social-button {
            bottom: auto;
            left: calc(50% - 8px); }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item.vertical-item .gallery-item-social-button.gallery-item-social-love {
              top: calc(100% / 4 - 10px); }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item.vertical-item .gallery-item-social-button.gallery-item-social-share {
              top: auto;
              right: auto;
              bottom: calc(100% / 4 - 10px); }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social.small-item.vertical-item .gallery-item-social-button.gallery-item-social-download {
              top: calc(50% - 10px); }
        :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box {
          position: absolute;
          top: 0;
          left: 50%;
          width: 100%;
          height: 100%;
          max-width: 300px;
          min-width: 200px;
          overflow: visible;
          z-index: 16;
          font-size: 12px;
          opacity: 0;
          transform: translateX(-50%);
          margin-top: 1px;
          margin-left: -3px;
          transition: opacity 0.4s ease;
          outline: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.hidden {
            opacity: 0 !important;
            pointer-events: none; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button,
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i {
            display: inline-block;
            font-size: 15px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: 22px;
            text-align: center;
            transform: translateY(-50%);
            background: transparent;
            border: none; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button:hover,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i:hover {
              opacity: 0.7; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button.network-1,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i.network-1 {
              margin-left: 5px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button.network-2,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i.network-2 {
              font-size: 13px;
              margin-top: 1px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button.network-4,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i.network-4 {
              margin-left: -1px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box button.network-5,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box i.network-5 {
              font-size: 13px;
              margin-top: 1px;
              margin-left: -3px; }
          :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item {
            top: 50%;
            left: 0;
            max-width: none;
            min-width: 0;
            max-height: 300px; }
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item button,
            :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item i {
              left: 50%;
              margin-left: -10px;
              margin-top: 8px; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item button.network-2,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item i.network-2 {
                font-size: 13px; }
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item button.network-5,
              :global div.pro-gallery .gallery-item-container .gallery-item-wrapper .gallery-item-hover .gallery-item-social-share-box.vertical-item i.network-5 {
                font-size: 13px; }
    :global div.pro-gallery .gallery-item-container .gallery-item-bottom-info, :global div.pro-gallery .gallery-item-container .gallery-item-top-info {
      box-sizing: border-box;
      cursor: pointer; }
      :global div.pro-gallery .gallery-item-container .gallery-item-bottom-info .gallery-item-title, :global div.pro-gallery .gallery-item-container .gallery-item-top-info .gallery-item-title {
        /*! autoprefixer: ignore next */
        overflow: hidden;
        /*! autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; }
      :global div.pro-gallery .gallery-item-container .gallery-item-bottom-info .gallery-item-description, :global div.pro-gallery .gallery-item-container .gallery-item-top-info .gallery-item-description {
        /*! autoprefixer: ignore next */
        overflow: hidden;
        display: -webkit-box;
        /*! autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; }
        :global div.pro-gallery .gallery-item-container .gallery-item-bottom-info .gallery-item-description > span, :global div.pro-gallery .gallery-item-container .gallery-item-top-info .gallery-item-description > span {
          white-space: normal; }
      :global div.pro-gallery .gallery-item-container .gallery-item-bottom-info .custom-button-wrapper button, :global div.pro-gallery .gallery-item-container .gallery-item-top-info .custom-button-wrapper button {
        outline: none;
        border: none;
        background: transparent; }
  :global div.pro-gallery.one-row {
    white-space: nowrap;
    float: left; }
    :global div.pro-gallery.one-row .gallery-column {
      width: 100%;
      float: none;
      white-space: nowrap; }
      :global div.pro-gallery.one-row .gallery-column .gallery-group {
        display: inline-block;
        float: none; }
    :global div.pro-gallery.one-row.slider .gallery-column {
      overflow-x: scroll; }
      :global div.pro-gallery.one-row.slider .gallery-column.scroll-snap {
        scroll-snap-type: x mandatory; }
    :global div.pro-gallery.one-row .gallery-horizontal-scroll-inner {
      position: relative; }
  :global div.pro-gallery.thumbnails-gallery {
    overflow: hidden;
    float: left; }
    :global div.pro-gallery.thumbnails-gallery .galleryColumn {
      position: relative;
      overflow: visible; }
    :global div.pro-gallery.thumbnails-gallery .thumbnailItem {
      position: absolute;
      background-color: white;
      background-size: cover;
      background-position: center;
      overflow-y: inherit;
      border-radius: 0px;
      cursor: pointer;
      transition: all .4s ease;
      transition-property: top, left, width, height; }
    :global div.pro-gallery.thumbnails-gallery .gallery-item-container .custom-button-wrapper {
      display: none !important; }
  @media (max-width: 500px) {
    :global div.pro-gallery.thumbnails-gallery {
      overflow: visible; } }
  :global div.pro-gallery *:focus {
    box-shadow: none; }
  :global div.pro-gallery.accessible i:focus,
  :global div.pro-gallery.accessible button:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 1px 2px #3899ec; }
  :global div.pro-gallery.accessible .has-custom-focus:focus {
    box-shadow: inset 0 0 0 1px #fff, inset 0 0 1px 4px #3899ec; }
  :global div.pro-gallery.accessible .gallery-item-hover i:focus,
  :global div.pro-gallery.accessible .gallery-item-hover button:focus {
    box-shadow: none; }
  :global div.pro-gallery.accessible .gallery-item-container.highlight::before {
    box-shadow: inset 0 0 1px 2px #3899ec, inset 0 0 7px 0 #fff, 0 0 10px -5px #3899ec; }
  :global div.pro-gallery.accessible .gallery-item-container:focus::after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 1px 2px #3899ec, inset 0 0 7px 0 #fff, 0 0 10px -5px #3899ec;
    pointer-events: none;
    border-radius: inherit; }
  :global div.pro-gallery.accessible .pro-gallery-thumbnails-highlighted::before {
    box-shadow: inset 0 0 1px 2px #3899ec, inset 0 0 7px 0 #fff, 0 0 10px -5px #3899ec; }
  :global div.pro-gallery.accessible .pro-gallery-thumbnails-highlighted::after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 1px 2px #3899ec, inset 0 0 7px 0 #fff, 0 0 10px -5px #3899ec;
    pointer-events: none;
    border-radius: inherit; }
  :global div.pro-gallery .hide-scrollbars {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none; }
    :global div.pro-gallery .hide-scrollbars::-webkit-scrollbar,
    :global div.pro-gallery .hide-scrollbars ::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important; }
  :global div.pro-gallery .rtl {
    direction: rtl; }

:global .screen-logs {
  word-wrap: break-word;
  background: #fff;
  width: 280px;
  font-size: 10px; }

@keyframes changing_background {
  0% {
    background-color: rgba(241, 241, 241, 0.2); }
  50% {
    background-color: rgba(241, 241, 241, 0.8); }
  100% {
    background-color: rgba(241, 241, 241, 0.2); } }
