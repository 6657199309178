div.gallery {
  display: grid; }
  div.gallery div.cell {
    overflow: hidden;
    position: relative;
    display: block; }
    div.gallery div.cell div.imageWrapper {
      position: relative;
      overflow: hidden; }
      div.gallery div.cell div.imageWrapper:hover::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: red; }
      div.gallery div.cell div.imageWrapper img.image {
        object-fit: cover; }
